// Lithuanian
export default{
    form: {
        startSurveyButton: "Pradėkime",
        nextButton: "Toliau",
        submitAnswerButton: "Pateikti atsakymą",
        continueButton: "Tęsti",
        finishButton: "Baigti",
        headerPercentageCompletion: 'baigta',
        headerQuestionsCompleted: 'klausimai',
        headerDefaultErrorMessage: 'Ups! Kažkas nutiko ne taip.',
        brandingPoweredBy: 'Varomas',
        brandingPromoWhySurvey: 'Kam apklausa, jei gali naudoti <b>{product}</b>?',
        brandingPromoGetStarted: 'Pradėkite jau šiandien!',
        submissionPending: "Voiceform pateikiamas. Nepamirškite neuždaryti naršyklės lango.",
        submissionCanCloseWindow: "Voiceform sėkmingai pateiktas. Dabar galite uždaryti langą 👍",
        mediaAttachmentOverlayCloseButton: "Uždaryti",
        expiredFormHeader: "Ačiū, kad užsukote!",
        expiredFormMessage: "Ši apklausa baigėsi. Prašome susisiekti su voiceform kūrėju.",
        notPublishedFormHeader: "Voiceform nepublikuotas!",
        notPublishedFormMessage: "Ši apklausa nepublikuota. Prašome susisiekti su voiceform kūrėju.",
        notPublishedFormRetryButton: "Bandyti dar kartą",
        errorFormHeader: "Ups!",
        errorFormMessage: "Kažkas nutiko ne taip. Pabandykite vėliau.",
        errorFormRetryButton: "Bandyti dar kartą",
        emptyFormHeader: "Ačiū, kad užsukote!",
        emptyFormMessage: "Ups! Šis formos lapas tuščias, nėra klausimų ar laukų. Atsiprašome už nepatogumus. Susisiekite su formos kūrėju dėl šios problemos. Ačiū už supratimą ir bendradarbiavimą!",

        restoreFormHeader: "Turite neužbaigtą formą!",
        restoreFormMessage: "Ar norite tęsti nuo ten, kur sustojote?",
        restoreFormButton: "Tęsti formą",
        restoreFormButtonNew: "Pradėti iš naujo",

        audioPermissionHeader: "Norite atsakyti balsu?",
        audioPermissionMessage: "Kalbėjimas pagreitins atsakymus.",
        audioPermissionAcceptButton: "Taip, noriu",
        audioPermissionDenyButton: "Ne, ačiū",
        audioPermissionErrorHeader: "Mikrofonas nepasiekiamas",
        audioPermissionErrorMessage: "Patikrinkite naršyklės leidimus, kad įjungtumėte mikrofoną.",
        audioPermissionErrorButton: "Gerai",
        audioRecorderFailedUpload: "Nepavyko įkelti įrašo",
        silenceDetectorError: "Nesigirdi. Patikrinkite mikrofono nustatymus.",
        audioRecorderButtonRecord: "Įrašyti",
        audioRecorderButtonRecordMore: "Įrašyti daugiau",
        voiceResponseSelectionMessage: "Pasirinkite, kaip norite atsakyti...",
        voiceResponseSelectionOrDivider: "arba",
        voiceResponseTextInputPlaceholder: "Įrašykite atsakymą",
        voiceResponseTextInputCharacterCounterMinMessage: "Įveskite mažiausiai {min} simbolių",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} simbolių (mažiausiai {min} simbolių)",
        voiceResponseTextInputCharacterValidationMessage: "{count} simbolių (mažiausiai {min} simbolių)",

        // checkbox
        checkboxValidationTooFew: "Pasirinkite bent {min} parinktį(-is)",
        checkboxValidationTooMany: "Pasirinkite ne daugiau nei {max} parinktį(-is)",
        checkboxNoneOfTheAboveOption: "Nė vienas iš aukščiau",
        checkboxInvalidAnswer: "Netinkamas atsakymas.",

        // datepicker
        datePickerPlaceholder: "Pasirinkite datą",

        // dropdown
        // email
        emailLabel: "El. paštas",
        emailInvalid: "Neteisingas el. paštas.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Įrašyti vaizdo įrašą",
        fileUploadPluginScreenCast: "Ekrano įrašas",
        fileUploadVideoImportFilesDefault: 'Įrašykite arba įkelkite vaizdo įrašą per:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Pasirinkite vaizdo įrašymo parinktį:',
        fileUploadVideoImportFilesNoCamera: 'Paspauskite mygtuką ekrano įrašymui',
        fileUploadVideoImportFilesNoScreenCast: 'Paspauskite mygtuką vaizdo įrašymui',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nėra galimybių vaizdo įrašymui',

        // matrix
        matrixValidationMessage: "Atsakymas teisingas.",
        matrixRow: "Eilutė",
        matrixColumn: "Stulpelis",
        matrixRowRequired: "Eilutė {rowTitle} privaloma.",
        matrixRadioGroup: "Radio grupė",
        matrixCheckboxGroup: "Checkbox grupė",
        matrixGroupRequired: "Eilutei {rowTitle}. {type} {groupTitle} privaloma.",
        matrixColumnRequired: "Eilutei {rowTitle}. Stulpelis {columnTitle} privalomas.",
        matrixColumnInvalid: "Eilutei {rowTitle}. Stulpelis {columnTitle} netinkamas.",
        matrixRequired: "Privaloma.",
        matrixNumericMustBeNumber: "Turi būti skaičius.",
        matrixNumericMustBeGreaterThenMin: "Turi būti didesnis nei {min}.",
        matrixNumericMustBeLessThenMax: "Turi būti mažesnis nei {max}.",
        matrixNumericMustBeInteger: "Turi būti sveikasis skaičius.",
        matrixNumericInvalidNumber: "Netinkamas skaičius.",

        // name
        nameLabel: "Vardas",
        nameInvalid: "Nurodykite vardą ir pavardę.",

        // nps
        npsNotLikely: "Visai neįtikėtina",
        npsExtremelyLikely: "Labai tikėtina",

        // numeric input
        numericInputRequired: "Privaloma.",
        numericInputMustBeNumber: "Turi būti skaičius.",
        numericInputMustBeGreaterThenMin: "Turi būti didesnis nei {min}.",
        numericInputMustBeLessThenMax: "Turi būti mažesnis nei {max}.",
        numericInputMustBeInteger: "Turi būti sveikasis skaičius.",
        numericInputInvalidNumber: "Netinkamas skaičius.",
        numericInputPlaceholder: "Įveskite skaičių",

        // phone
        phoneInvalid: "Telefono numeris neteisingas.",
        phoneCountrySelectorLabel: 'Šalies kodas',
        phoneCountrySelectorError: 'Pasirinkite šalį',
        phoneNumberLabel: 'Telefono numeris',
        phoneExample: 'Pavyzdys:',

        // boolean
        booleanYesLabel: "Taip",
        booleanNoLabel: "Ne",

        //questionStep
        questionStepAudioQuestionLabel: "Garso klausimas",

        // errors
        invalidPhoneNumber: "{phone} neteisingas telefono numeris.",
        invalidEmail: "{email} neteisingas el. pašto adresas.",
        questionIsRequired: "Klausimas privalomas.",
        answerIsNotValid: "Atsakymas netinkamas.",
        unfinishedProbingDialogError: "Užbaikite dialogą.",
        cannotResumePartialResponse: "Nepavyksta tęsti dalinės atsakymo.",
        failedToSubmitForm: "Nepavyko pateikti formos. Patikrinkite interneto ryšį ir bandykite dar kartą.",

        //language picker
        searchLanguage: "Ieškoti kalbos",
    }
}
